import "./Styles.css";

import React, { useState, useEffect } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import "./Styles.css";

function transformFolderName(folderName) {
  // Remove trailing slash and split into words
  const words = folderName.replace(/\/$/, "").split(/(?=[A-Z0-9])/);

  // Capitalize the first letter of each word
  const formattedWords = words.map((word, index) => {
    if (index === 0) {
      return word; // Keep the first word as it is
    } else if (/^\d+$/.test(word)) {
      return " " + word; // Add a space before the first digit
    } else {
      return word.charAt(0).toUpperCase() + word.slice(1);
    }
  });

  // Join the words with spaces and return the formatted name
  return formattedWords.join(" ");
}

const Textures = () => {
  const [imageData, setImageData] = useState([]);
  const [selectedTag, setSelectedTag] = useState("All"); // Default to "All" initially
  const [loading, setLoading] = useState(true); // New state for loading indicator

  useEffect(() => {
    // fetch("http://localhost:5000/api/images/tags")

    // fetch("http://54.206.56.255:5000/api/images/tags", {
    fetch("https://api.simonhatton.net:3443/api/images/tags", {})
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setImageData(data);
        setLoading(false); // Set loading to false when data is fetched
      })
      .catch((error) => {
        console.error("Error fetching images:", error);
        setLoading(false); // Set loading to false in case of an error
      });
  }, []);

  // Function to handle tag selection
  const handleTagSelect = (tag) => {
    setSelectedTag(tag);
  };

  // Filter the imageData based on the selected tag
  const filteredImageData =
    selectedTag === "All"
      ? imageData
      : imageData.filter((folder) => folder.tags.includes(selectedTag));

  return (
    <div className="container">
      <div className="textures-content">
        <h1>Textures</h1>
        <div className="filter-gallery-container">
          <div className="filter-menu">
            <h3>Filter Menu</h3>
            <ul>
              <ul>
                <li>
                  <button onClick={() => handleTagSelect("All")}>All</button>
                </li>
                <li>
                  <button onClick={() => handleTagSelect("Ceramic")}>
                    Ceramic
                  </button>
                </li>
                <li>
                  <button onClick={() => handleTagSelect("Cloth")}>
                    Cloth
                  </button>
                </li>
                <li>
                  <button onClick={() => handleTagSelect("Concrete")}>
                    Concrete
                  </button>
                </li>
                <li>
                  <button onClick={() => handleTagSelect("Organic")}>
                    Organic
                  </button>
                </li>
                <li>
                  <button onClick={() => handleTagSelect("Plastic")}>
                    Plastic
                  </button>
                </li>
                <li>
                  <button onClick={() => handleTagSelect("Rubber")}>
                    Rubber
                  </button>
                </li>
                <li>
                  <button onClick={() => handleTagSelect("Steel")}>
                    Steel
                  </button>
                </li>
                <li>
                  <button onClick={() => handleTagSelect("Tile")}>Tile</button>
                </li>
              </ul>
            </ul>
          </div>
          <div className="image-gallery">
            {loading ? (
              <div className="loading-message">
                <p>Loading...</p>
                <div className="loading-spinner"></div>
              </div>
            ) : filteredImageData.length === 0 ? (
              <p>No images to display for the selected tag.</p>
            ) : (
              filteredImageData.map((folder) => (
                <div key={folder.folderName} className="folder-container">
                  <Carousel showThumbs={false} showStatus={false}>
                    {folder.images.map((imageUrl) => (
                      <div key={imageUrl} className="carousel-image">
                        <img src={imageUrl} alt="Texture" />
                      </div>
                    ))}
                  </Carousel>
                  <h3 className="folder-name">
                    {transformFolderName(folder.folderName)}
                  </h3>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Textures;
