import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import "./App.css";

import Contact from "./Contact";
import Tutorials from "./Tutorials";
import Header from "./Header";
import Footer from "./Footer";
import Home from "./Home";
import About from "./About";

import Textures from "./Textures";
import Terms from "./Terms";
import Faqs from "./FAQ";

function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/tutorials" element={<Tutorials />} />

        <Route path="/textures" element={<Textures />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/faq" element={<Faqs />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
