import React from "react";
import "./Styles.css";
import { Link } from "react-router-dom";
import facebookIcon from "./images/FB.jpg";
import youtubeIcon from "./images/YouTube.webp";
import instagramIcon from "./images/Insta.webp";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-info">
          <a href="mailto:info@texturelodge.com">info@texturelodge.com</a>
        </div>
        <div className="footer-links">
          <Link to="/contact">Contact Us</Link>
          <Link to="/terms">Terms & Conditions</Link>
          <Link to="/faq">FAQ</Link>
        </div>
        <div className="footer-social-links">
          <a
            href="https://business.facebook.com/texturelodge"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={facebookIcon} alt="Facebook" />
          </a>
          <a
            href="https://www.youtube.com/user/texturelodge"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={youtubeIcon} alt="Youtube" />
          </a>
          <a
            href="https://www.instagram.com/texturelodge/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={instagramIcon} alt="Instagram" />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
