import React from "react";
import "./Styles.css";

const Faqs = () => {
  return (
    <div className="container">
      <div className="content">
        <h1 className="faqs-title">Frequently asked questions</h1>
        <div className="faq-body">
          <h2 className="faqs-question">
            Do you make all of your own textures?
          </h2>
          <p className="faqs-answer">
            Yes. All of our textures have been built from scratch either through
            modelling geometry or photography and lidar.
          </p>
          <h2 className="faqs-question">
            Why are your images different resolutions?
          </h2>
          <p className="faqs-answer">
            It all depends on the texture. Repetative textures don't have a lot
            of detail that needs to be delivered. So the textures are able to be
            1024 and tiled to a much higher resolution. Whereas some of our
            photographed textures go to 4K and beyond.
          </p>
          <h2 className="faqs-question">
            Which software are the texture able to be used in?
          </h2>
          <p className="faqs-answer">
            All of the texture files that are downloaded are images. As well as
            the more well known pieces of 3D software such as Autodesk Maya,
            Blender, Substance Painter, Cinema 4D etc, as long as your software
            allows you to import images, you're all set.
          </p>
          <h2 className="faqs-question">Do you do custom orders.</h2>
          <p className="faqs-answer">
            We don't see why not. Depending on how specific you need to be. If
            you're able to take photos or get a 3D scan of a texture, I'm sure
            we could sort something out. Get in touch to have a chat.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Faqs;
