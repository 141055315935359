import React from "react";
import "./Styles.css";
import square from "./images/sqaure.png";

const Tutorials = () => {
  return (
    <div className="container">
      <div className="content">
        <h1>Tutorials</h1>
        <h2>Arnold Shaders for Autodesk Maya</h2>
        <div className="tutorial-cards">
          <a className="tutorial-card" href="/tutorial1">
            <div className="image-wrapper">
              <img src={square} alt="Tutorial 1" />
              <h3>Tutorial 1</h3>
            </div>
          </a>
          <a className="tutorial-card" href="/tutorial2">
            <div className="image-wrapper">
              <img src={square} alt="Tutorial 2" />
              <h3>Tutorial 2</h3>
            </div>
          </a>
          <a className="tutorial-card" href="/tutorial3">
            <div className="image-wrapper">
              <img src={square} alt="Tutorial 3" />
              <h3>Tutorial 3</h3>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Tutorials;
