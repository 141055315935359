import logoImage from "./images/TextureLodgeLogo.PNG";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import "./Styles.css"; // Import the CSS file for the header styles

const Header = () => {
  const location = useLocation();

  const isActiveLink = (path) => {
    return location.pathname === path;
  };

  return (
    <header className="header">
      <div className="logo">
        <img src={logoImage} alt="Logo" />
      </div>
      <nav>
        <ul className="nav-links">
          <li>
            <Link to="/" className={isActiveLink("/") ? "active" : ""}>
              Home
            </Link>
          </li>
          <li>
            <Link
              to="/textures"
              className={isActiveLink("/textures") ? "active" : ""}
            >
              Textures
            </Link>
          </li>
          <li>
            <Link
              to="/tutorials"
              className={isActiveLink("/tutorials") ? "active" : ""}
            >
              Tutorials
            </Link>
          </li>
          <li>
            <Link
              to="/about"
              className={isActiveLink("/about") ? "active" : ""}
            >
              About
            </Link>
          </li>
          <li>
            <Link
              to="/contact"
              className={isActiveLink("/contact") ? "active" : ""}
            >
              Contact
            </Link>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
