import React from "react";
import "./Styles.css";
import CompareImage from "react-compare-image";
import before from "./images/before.png";
import after from "./images/after.png";

const Home = () => {
  return (
    <div className="container">
      <div className="content">
        <h1 className="home-title">High-Resolution Textures for 3D Artists</h1>
        <div className="home-search">
          <a href="/textures">
            <button className="home-search-button">Search Textures</button>
          </a>
        </div>

        <div className="image-container">
          <CompareImage
            leftImage={before}
            rightImage={after}
            sliderLineColor="#ffffff"
            sliderLineWidth={2}
            handleSize={50}
            handleBackgroundColor="#ffffff"
            handleBorderColor="#ffffff"
          />
        </div>
      </div>
    </div>
  );
};

export default Home;
