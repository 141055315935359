import React, { useState } from "react";
import "./Styles.css";
import emailjs from "emailjs-com";

const Contact = () => {
  emailjs.init("FNaF2M7xTMCfrJiBK");
  const [successMessage, setSuccessMessage] = useState("");

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const handleFormSubmit = (e) => {
    e.preventDefault();

    // Send the email using EmailJS
    emailjs
      .send("service_7tviiq6", "template_dmh67g9", {
        subject: formData.subject,
        name: formData.name,
        message: formData.message,
        email: formData.email,
      })
      .then((response) => {
        console.log("Email sent:", response.text);
        // Clear form fields and display success message
        setFormData({
          name: "",
          email: "",
          subject: "",
          message: "",
        });
        setSuccessMessage("Message sent successfully");
      })
      .catch((error) => {
        console.error("Error sending email:", error);
        // TODO: Handle error or display an error message
      });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };
  const handleButtonClick = (e) => {
    handleFormSubmit(e);
    setSuccessMessage("Message sent successfully");
  };

  return (
    <div className="container">
      <div className="content">
        <h1>
          Got a question, suggestion or see something that needs attention?
        </h1>
        <h1>Let us know below.</h1>
        {successMessage && <p>{successMessage}</p>}
        <form onSubmit={handleFormSubmit}>
          <div className="form-group">
            <label htmlFor="name">Name:</label>
            <input
              type="text"
              id="name"
              name="name"
              placeholder="Your Name"
              value={formData.name}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Your Email"
              value={formData.email}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="subject">Subject:</label>
            <input
              type="text"
              id="subject"
              name="subject"
              placeholder="Subject"
              value={formData.subject}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="message">Message:</label>
            <textarea
              id="message"
              name="message"
              placeholder="Your Message"
              rows="5"
              value={formData.message}
              onChange={handleInputChange}
              required
            ></textarea>
          </div>
          {successMessage ? null : (
            <div className="form-group">
              <button type="submit" onClick={handleButtonClick}>
                Send
              </button>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default Contact;
