import React from "react";
import "./Styles.css";

const About = () => {
  return (
    <div className="container">
      <div className="content">
        <h1 className="about-title">About Us</h1>
        <p className="about-description">
          The team at Texture Lodge has experience in all areas of VFX and
          Feature Films and knows what it takes to create good quality,
          high-resolution textures.
        </p>
        <p className="about-description">
          Our images are able to be plugged in straight out of the proverbial
          box, but we've also made them as user-friendly as possible for those
          who wish to make adjustments to suit whatever creation they've
          envisaged.
        </p>
        <p className="about-description">
          We're constantly surrounded by amazing textures that we want to
          recreate for you, and because of that, you'll see new and creative
          textures and assets pop up as frequently as we can get them out.
        </p>
      </div>
    </div>
  );
};

export default About;
