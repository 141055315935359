import React from "react";
import "./Styles.css";

const Terms = () => {
  return (
    <div className="container">
      <div className="content">
        <h1 className="terms-title">Texture Lodge End-User Agreement</h1>
        <div className="terms-body">
          <p className="terms-description">
            Last Updated: 26th September 2021{" "}
          </p>

          <p className="terms-body">
            Texture Lodge is a website offering textures for digital artists and
            their projects (the “Service”). This website www.texturelodge.com,
            is owned and operated by a sole trader ABN 147 085 692 10 (“The
            Owner”). This End-User License Agreement (this “Agreement”, “EULA”,
            “License”) applies to tall users of the Service (collectively or
            individually, “You”, “Users”, “Customer”). By agreeing to the Terms
            and Conditions or by using any Texture Lodge Services, you
            acknowledge that you have read, understood, agree with, and accept
            all the terms and conditions contained or expressly reference in
            this agreement. If you do not agree to all the terms and conditions
            of this agreement, then please do not attempt to use this Service.
            If you are entering into this agreement on behalf of a company or
            other legal entity, you represent that you have the authority to
            bind such entity and it’s affiliated to these terms and conditions,
            in which case the terms “you” or “your” shall refer to such entity,
            its affiliates, and users associated with it. If you do not have
            such authority, or if you do not agree with these terms and
            conditions, you must not accept this agreement and may not use the
            services.
          </p>

          <ol type="1">
            <li>
              ACCEPTANCE
              <ol>
                <li>
                  This Agreement is a legal agreement between you and the Owner,
                  and governs your use of the Service and related products to
                  You by Texture Lodge. The purpose of this Agreement and the
                  License is to allow You to use the Service provided by Texture
                  Lodge during the Term on the terms and conditions stated in
                  this Agreement, to develop products for either personal or
                  commercial use (“Customer Assets”).
                </li>
              </ol>
            </li>
            <li>
              ACCOUNTS
              <ol>
                <li>
                  To use the service you must either:
                  <ul>
                    <li>Register an account via Texture Lodge’s website: or</li>
                    <li>
                      Access the Service and provide the required user
                      information
                    </li>
                    <li>
                      You may not, under any circumstances, whether
                      intentionally, or through negligence or inaction, allow or
                      enable persons other than the Permitted Users to access
                      your account, unless specifically permitted in writing by
                      the Owner.
                    </li>
                    <li>You may delete your account at any time</li>
                  </ul>
                </li>
              </ol>
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
};

export default Terms;
